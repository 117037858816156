<template>
	<div class="intersection_base" :class="{non_intersection:!isActive}"><slot></slot></div>
</template>

<script>

import isMobile from 'ismobilejs';

export default {
	name:'intersectionWrap',
	data:function(){
		return {
			threshold:[],
			state:false,
			iObs:{},
		}
	},
	created:function(){
		this.setThreshold();
		if(!('IntersectionObserver' in window)){
			document.body.classList.add('polyfill');
		}
		this.iObs = new IntersectionObserver(this.checkTarget,{threshold:this.threshold});
	},
	mounted:function(){
		this.iObs.observe(this.$el);
	},
	methods:{
		setThreshold:function(){
			if(this.isNarrow()){
				this.threshold = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1];
			}else{
				this.threshold = [0,0.25,0.4,0.5,0.6,0.75,1];
			}
		},
		checkTarget:function(entries){
			if(this.isInside(entries[0].target)){
				this.state = true;
				this.iObs.unobserve(this.$el);
			}
		},
		isInside:function(target){
			const wt = (window.screenTop || window.screenY || 0);
			const wb = wt + (window.innerHeight || document.documentElement.clientHeight || 0);
			const rect = target.getBoundingClientRect();
			const rt = rect.top;
			const rb = rect.bottom;
			const margin = rect.height * (this.isNarrow() ? 0.1 : 0.25);
			return (wb - rt > margin && rb - wt > margin);
		},
		isNarrow:function(){
			const isAny = isMobile.any;
			const isNarrowWindow = (window.innerWidth <= 480) ? true : false;
			return (isAny || isNarrowWindow);
		}
	},
	computed:{
		isActive:function(){
			return this.state;
		}
	}
};
</script>
