<template>
	<div v-if="state" id="side_nav">
		<div class="arrow_up"></div>
		<ul>
			<li
				v-for="target in list"
				:key="target.id"
				:class="{active:target.isCurrent}"
			>
			<a :href="'#' + target.id" data-scroll>&#9679;</a></li>
		</ul>
		<div class="arrow_down"></div>
	</div>
</template>



<script>

import isMobile from 'ismobilejs';

export default {
	name:'sideNav',
	data:function(){
		return {
			list:[
				{id:'lang_intro',isCurrent:false},
				{id:'lang_head_office',isCurrent:false},
				{id:'lang_kurosaka_factory',isCurrent:false},
				{id:'lang_works',isCurrent:false},
				{id:'lang_work_landscape',isCurrent:false},
				{id:'lang_committed',isCurrent:false},
				{id:'lang_message',isCurrent:false},
			],
			iObs:{},
			state:true,
		}
	},
	created:function(){
		this.setThreshold();
		if(!('IntersectionObserver' in window)){
			document.body.classList.add('polyfill');
		}
		this.iObs = new IntersectionObserver(this.checkTarget,{threshold:this.threshold});
	},
	mounted:function(){
		this.setObserve();
		window.addEventListener('resize',this.setState);
	},
	methods:{
		setObserve:function(){
			this.list.forEach(v => {
				this.iObs.observe(document.getElementById(v.id));
			});
		},
		setThreshold:function(){
			if(this.isNarrow()){
				this.threshold = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1];
			}else{
				this.threshold = [0,0.2,0.4,0.5,0.6,0.8,1];
			}
		},
		checkTarget:function(entries){
			const check = entries.filter(entry => {
				return this.isWindowMiddle(entry.target);
			});
			if(!check.length) return;
			this.setCurrent(check[0].target.id);
		},
		isWindowMiddle:function(target){
			const wt = (window.screenTop || window.screenY || 0);
			const wb = wt + (window.innerHeight || document.documentElement.clientHeight || 0);
			const wm = wt + ((wb - wt) / 2);
			const rect = target.getBoundingClientRect();
			const rt = rect.top;
			const rb = rect.bottom;
			return (wm - rt > 0 && wm - rb < 0);
		},
		setCurrent:function(id){
			this.list.forEach(v => {
				if(v.id === id){
					v.isCurrent = true;
				}else{
					v.isCurrent = false;
				}
			});
		},
		isNarrow:function(){
			const isAny = isMobile.any;
			const isNarrowWindow = (window.innerWidth <= 480) ? true : false;
			return (isAny || isNarrowWindow);
		},
		setState:function(){
			this.state = !this.isNarrow();
		}
	},
}



</script>