<template>
	<div id="langSlider">
		<carousel
			ref="carousel"
			:per-page="1"
			:navigation-enabled="false"
			:speed="500"
		>
			<slide v-for="(item,i) in items">
				<div class="slideWrap">
					<img :src="getImgPath(item.num)">
					<div class="text">{{ getText(i) }}</div>
				</div>
			</slide>
		</carousel>
		<div class="btn_wrap">
			<ul>
				<li
					v-for="(item,i) in items"
					class="btn"
					:class={active:item.isActive}
					:style="getThumbBg(item.num)"
					@click="slideTo(i)"
				>
					<span>{{ getText(i) }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>


<script>

import { Carousel, Slide } from 'vue-carousel';



export default {
	name:'langSlider',
	data:function(){
		return {
			preIndex: 0,
			items:[
				{
					num:1,
					vi:'Mẫu vật trang trí tường',
					id:'Kantor Pusat untuk benda seni',
					isActive:true,
				},
				{
					num:2,
					vi:'Mẫu vật trang trí tường – Xưởng Kurosaka',
					id:'Pabrik yang memproduksi benda seni hiasan dinding',
					isActive:false,
				},
				{
					num:3,
					vi:'Sự thanh bình bên trong công xưởng',
					id:'Di bengkel Wabisabi',
					isActive:false,
				},
				{
					num:4,
					vi:'Bên ngoài công xưởng Thanh Bình',
					id:'Eksterior studio Wabisabi',
					isActive:false,
				},
			]
		};
	},
	props:['lang'],
	components: {
		Carousel,
		Slide
	},
	methods:{
		slideTo:function(targetId){
			console.log(this.$refs.carousel)
			this.$refs.carousel.goToPage(targetId);
			this.changeActive(targetId);
		},
		getText:function(i){
			if(this.lang == null) return '';
			return this.items[i][this.lang];
		},
		getImgPath:function(num){
			return '/img/fl_06_slide_' + num + '.png';
		},
		getThumbBg:function(num){
			return 'background-image:url(/img/fl_06_slide_' + num + '_th.png);'
		},
		changeActive:function(i){
			this.items.forEach(item => {
				item.isActive = false;
			});
			this.items[i].isActive = true;
		}
	}
}

</script>

