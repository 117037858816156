<template>
	<a :class="{active:isActive}" class="menu-trigger" href="#">
		<span></span>
		<span></span>
		<span></span>
	</a>
</template>


<script>

export default {
	name:'hamburgerMenu',
	data:function(){
		return {
			state:false
		}
	},
	created:function(){
		this.setParentEvent();
	},
	methods:{
		toggle:function(bool){
			this.state = bool;
		},
		setParentEvent:function(){
			this.$parent.$on('toggle',this.toggle);
		}
	},
	computed:{
		isActive:function(){
			return this.state;
		}
	}
}

</script>

<style scoped>

menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.menu-trigger {
  position: relative;
  width: 100%;
  height: 80%;
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 44%;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger.active {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.menu-trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY(370%) rotate(-45deg);
  transform: translateY(370%) rotate(-45deg);
}
.menu-trigger.active span:nth-of-type(2) {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
.menu-trigger.active span:nth-of-type(3) {
  opacity: 0;
}

</style>