<template>
	<div id="sp_nav" :class="pageClass">
		<div class="trigger robo_b" @click="toggleMenu">
			<div class="home_title">Menu</div>
			<hamburger-menu class="btn"></hamburger-menu>
		</div>
			<div class="bellow" :class="{active:isActive}" ref="bellow">
				<ul>
					<li v-for="item in spSitemap" :key="item.key" v-if="item.type === 'category'" class="category">{{item.label}}</li>
					<li v-else :key="item.key">
						<a :href="item.path" :title="item.label">{{item.label}}</a>
					</li>
					<li class="category robo_b">Language</li>
					<li class="language robo_b"><a href="/" title="Japanese">JA</a></li>
					<li class="language robo_b"><a href="/language/indonesian" title="Indonesian">ID</a></li>
					<li class="language robo_b"><a href="/language/vietnamese" title="Vietnamese">VI</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

import hamburgerMenu from './hamburgerMenu.vue';

export default {
	name:'spNav',
	data:function(){
		return {
			spSitemap:[],
			isActive:false,
			bellowHeight:''
		}
	},
	props:['pageClass','sitemap'],
	mounted:function(){
		this.createSpSitemap();
		this.$refs.bellow.style.height = 0;
	},
	components:{
		hamburgerMenu:hamburgerMenu,
	},
	methods:{
		createSpSitemap:function(){
			const list = [];
			const categories = this.sitemap.footerSitemap;
			categories.forEach(category => {
				list.push({key:category,label:this.sitemap.categories[category].name,type:'category'});
				const cateList = this.sitemap.categories[category].list
				cateList.forEach(page => {
					list.push({key:page,label:this.sitemap.pages[page].name,path:this.sitemap.pages[page].path,type:'page'});
				});
			});
			this.spSitemap = list;
		},
		toggleMenu:function(){
			this.isActive = !this.isActive;
			this.setStyle();
			this.$emit('toggle',this.isActive);
		},
		setStyle:function(){
			if(this.isActive){
				this.$refs.bellow.style.height = this.$refs.bellow.children[0].clientHeight + 'px';
			}else{
				this.$refs.bellow.style.height = '0';
			}
		},
		test:function(){
			console.log('test')
		}
	},
	computed:{
	}
}

</script>
