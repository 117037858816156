import '@babel/polyfill';
import Vue from 'vue';
import 'es6-promise/auto';
import YkSlider from './YkSlider';
import baguetteBox from 'baguettebox.js';
import ykAccordion from './ykAccordion.vue';
import intersectionWrap from './intersectionWrap.vue';
import resizeHeader from './resizeHeader.vue';
import formTable from './formTable.vue';
import spNav from './spNav.vue';
import pageLoading from './pageLoading.vue';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js';
import SweetScroll from 'sweet-scroll';
import langSlider from './langSlider.vue';
import sideNav from './sideNav.vue';


//------------------------------------
(function(){
//------------------------------------

const initVue = () => {

	new Vue({
		el:'#wrap',
		data:{
			sitemap:{},
			pages:[],
			categories:[],
			navCategories:[],
			footerSitemap:[],
			spSitemap:[]
		},
		components:{
			ykAccordion:ykAccordion,
			intersectionWrap:intersectionWrap,
			resizeHeader:resizeHeader,
			formTable:formTable,
			pageLoading:pageLoading,
			spNav:spNav,
			langSlider:langSlider,
			sideNav:sideNav,
		},
		created:function(){
			this.initSitemap();
		},
		methods:{
			initSitemap:function(){
				this.sitemap = sitemap;
				this.pages = Object.keys(this.sitemap.pages);
				this.categories = Object.keys(this.sitemap.categories);
				this.navCategories = this.sitemap.navCategories;
				this.footerSitemap = this.sitemap.footerSitemap;
			},
			getCategoryLength:function(category){
				return this.sitemap.categories[category].list.length;
			},
			getPageObj:function(page){
				return this.sitemap.pages[page];
			}
		}
	});


};


const insertIntersectionWrapTags = () => {
	const targetClass = '.intersectionTarget';

	const wrap = function(element) {
		const wrapper = document.createElement('intersection-wrap');
		element.parentNode.insertBefore(wrapper, element);
		wrapper.appendChild(element);
	};

	const nodelist = document.querySelectorAll(targetClass);
	const targets = Array.prototype.slice.call(nodelist,0);
	targets.forEach(el => {
		wrap(el);
	});
};

const addLoadedClass = () => {
	document.documentElement.classList.add('loaded');
}

const initSlider = () => {
	const ykSlider = new YkSlider('home_slider');
};


const initBaguetteBox = () => {
	baguetteBox.run('.bbGallery');
};


const initSweetScroll = () =>{
	const sweetScroll = new SweetScroll();
}

//------------------------------------

document.addEventListener('DOMContentLoaded',() => {
	insertIntersectionWrapTags();
	initVue();
	initSlider();
	initSweetScroll();
},false);

window.addEventListener('load',() => {
	addLoadedClass();
	initBaguetteBox();
});

//------------------------------------
})();
//------------------------------------
