<template>
	<div class="body" :class="{active:state}">
		<div ref="trigger" class="trigger"><slot name="trigger"></slot></div>
		<div class="bellows" ref="bellows" :style="setBellowsHeight"><slot name="bellows"></slot></div>
	</div>
</template>

<script>

import isMobile from 'ismobilejs';

export default {
	name:'ykAccordion',
	data:function(){
		return {
			state:false,
			bellowsHeight:0,
		}
	},
	props:['defaultActive','triggerEvent'],
	mounted:function(){
		this.addEvent();
		if(this.defaultActive){
			this.state = true;
		}
	},
	methods:{
		changeBellowheight:function(active){
			if(active){
				this.bellowsHeight = this.$refs.bellows.children[0].clientHeight;
			}else{
				this.bellowsHeight = 0;
			}
		},
		setState:function(bool){
			this.state = bool;
		},
		toggleState:function(){
			this.state = !this.state;
		},
		addEvent:function(){
			const isAny = isMobile.any;
			if(this.triggerEvent === 'click' || isAny){
				this.$refs.trigger.addEventListener('click',() => {
					this.toggleState();
				});
				this.$refs.trigger.style.cursor = 'pointer';
			}else{
				this.$el.addEventListener('mouseenter',() => {
					this.setState(true);
				});
				this.$el.addEventListener('mouseleave',() => {
					this.setState(false);
				});
			}
		},
	},
	computed:{
		isClickTrigger:function(){
			return this.triggerEvent === 'click';
		},
		setBellowsHeight:function(){
			return {
				height:this.bellowsHeight + 'px',
				overflow:'hidden',
			}
		}
	},
	watch:{
		state:function(val){
			this.changeBellowheight(val);
		}
	}
};
</script>