export default class{

	constructor(id,options){
		if(!document.getElementById(id)) return;
		this.id = id;
		this.options = options || null;
		this.name = 'ykSlider';
		this.originalClass = this.name + 'Original';
		this.windowClass = this.name + 'Window';
		this.selectorBoxClass = this.name + 'SelectorBox';
		this.wrap = document.getElementById(this.id);
		this.original = this.wrap.firstElementChild;
		this.wrap.classList.add(this.name);
		this.original.classList.add(this.originalClass);
		this.list = this.original.children;
		this.loaded = 0;
		this.listImgs = [];
		this.selectorList = [];
		this.lastChangeTime = +new Date();
		this.setDefaultSetting();
		this.setOptions();
		this.setWindow();
		this.setSelector();
		this.setImgs();

		// console.log(this);
	}

	setDefaultSetting(){
		this.current = 0;
		this.changing = null;
		this.rate = 1.1;
		this.duration = 2;
		this.loopInterval = 5000;
		this.firstDelay = 500;
		this.type = 'fade';
		this.loopFlg = true;
	}

	setOptions(){
		if(!this.options) return;
		const keys = Object.keys(this.options);
		for(let i = 0;i < keys.length;i++){
			this[keys[i]] = this.options[keys[i]];
		}
	}

	setWindow(){
		this.original.style.display = 'none';
		this.window = document.createElement('ul');
		this.window.classList.add(this.windowClass);
		this.wrap.appendChild(this.window);
		this.windowW = this.window.clientWidth;
		this.windowH = this.window.clientHeight;
	}

	setImgs(){
		const checkLoad = (i) => {
			this.css(this.list[i],{backgroundImage:'url(' + this.listImgs[i].src + ')'});
			if(i === this.current) this.first();
			if(this.loaded === this.list.length) this.loop();
		};

		for(let i = 0;i < this.list.length;i++){
			this.css(this.list[i],{display:'none'});
			this.listImgs[i] = new Image();
			this.listImgs[i].addEventListener('load',() => {
				this.loaded++;
				checkLoad(i);
			});
			this.listImgs[i].src = this.list[i].getAttribute('data-ykSliderUri');
		}
	}

	setSelector(){
		this.selectorBox = document.createElement('ul');
		this.selectorBox.classList.add(this.selectorBoxClass);
		this.selectorBox.style.display = 'none';
		this.wrap.parentNode.insertBefore(this.selectorBox,this.wrap.nextSibling);
		for(let i = 0;i < this.list.length;i++){
			this.selectorList[i] = document.createElement('span');
			this.selectorBox.appendChild(this.selectorList[i]);
		}
		this.selectorBox.style.opacity = '0';
		this.selectorBox.style.display = 'block';
		this.selectorBox.style.width = (this.selectorList[0].clientWidth * this.selectorList.length) + 'px';
		this.selectorBox.style.opacity = '0.5';
		this.setSelectorCurrent();
		this.addSelectorEvent();
	}

	getNum(element){
		return this.list.indexOf(element);
	}

	getPrevNum(num){
		return (num === 0) ? this.list.length - 1 : num - 1;
	}

	getNextNum(num){
		return (num === this.list.length - 1) ? 0 : num + 1;
	}

	registerEvent(element,callback){
		const f = () => {
			element.removeEventListener('transitionend',f,false);
			callback();
		};
		element.addEventListener('transitionend',f,false);
	}

	css(element,cssObj,callback){
		const keys = Object.keys(cssObj);
		if(callback !== undefined){
			this.registerEvent(element,callback);
		}

		for(let i = 0;i < keys.length;i++){
			element.style[keys[i]] = cssObj[keys[i]];
		}
		this.reflow(element);
	}

	hide(element){
		this.css(element,{display:'none',opacity:'0',transform:'scale(1,1)',transitionDuration:'0s'});
	}

	reflow(element){
		element.offsetHeight;
		// console.log(element.offsetHeight);
	}

	change(after,force){
		if(this.current === after && !force) return;
		const clone = this.list[after].cloneNode(true);

		this.lastChangeTime = +new Date();
		this.current = after;
		this.changing = after;
		this.setSelectorCurrent();

		this.window.appendChild(clone);
		this.hide(clone);
		this.css(clone,{display:'block',opacity:'0',transitionDuration:this.duration + 's',transform:'scale(1,1)'});
		this.css(clone,{opacity:'1',transform:'scale(' + this.rate + ',' + this.rate + ')'},() => {
			const removeNode = clone.previousElementSibling;
			if(removeNode) removeNode.parentNode.removeChild(removeNode);
			this.changing = null;
		});
	}

	first(){
		setTimeout(() => {
			this.change(this.current,true);
		},this.firstDelay);
	}

	next(){
		this.change(this.getNextNum(this.current));
	}

	prev(){
		this.change(this.getPrevNum(this.current));
	}

	loop(){
		const now = +new Date();

		if(!this.loopFlg) return;
		this.loopId = requestAnimationFrame(() => {
			this.loop();
		});

		if(now - this.lastChangeTime < this.loopInterval || this.changing !== null) return;

		this.next();
		// console.clear();
		// console.log(this.lastChangeTime);
	}

	stopLoop(){
		this.loopFlg = false;
	}

	setSelectorCurrent(){
		for(const v of this.selectorList){
			v.classList.remove('current');
		}
		this.selectorList[this.current].classList.add('current');
	}

	addSelectorEvent(){
		for(let i = 0;i < this.selectorList.length;i++){
			this.selectorList[i].addEventListener('click',(e) => {
				this.change(this.selectorList.indexOf(e.target));
			});
		}
	}
}


