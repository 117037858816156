<template>
	<div :id="id" :class={narrow:state}><slot></slot></div>
</template>

<script>
export default {
	name:'resizeHeader',
	data:function(){
		return{
			border:300,
			state:false
		}
	},
	props:['id'],
	created:function(){
		if(this.id !== 'header') return;
		window.addEventListener('scroll',(e) => {
			this.checkPosition();
		});
	},
	methods:{
		checkPosition:function(){
			const top = document.scrollingElement.scrollTop;
			this.state = (top > this.border) ? true : false;
		}
	}
};
</script>