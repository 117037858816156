<template>
	<transition name="modal">
		<div class="modal-mask">
		<div class="modal-close" @click="$emit('hide')"><div></div></div>
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header">
						<slot name="header"></slot>
					</div>
					<div class="modal-body">
						<slot name="body"></slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<button class="modal-default-button" @click="$emit('hide')">OK</button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>


<script>
export default {
	name:'modal'
};
</script>


<style scoped>
.modal-mask {position: fixed;z-index: 9998;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, .5);transition: opacity .3s ease;}
.modal-wrapper {display: flex;align-items:center;width:100%;height:100%;}
.modal-container {width: 300px;max-height:90%;margin: 0px auto;padding: 20px 30px;background-color: #fff;border-radius: 2px;box-shadow: 0 2px 8px rgba(0, 0, 0, .33);transition: all .3s ease;}
.modal-header h3 {margin-top: 0;color: #42b983;}
.modal-body {margin: 20px 0;}
.modal-default-button {float: right;}
.modal-enter {opacity: 0;}
.modal-leave-active {opacity: 0;}
.modal-enter .modal-container,.modal-leave-active .modal-container {-webkit-transform: scale(1.1);transform: scale(1.1);}
.modal-close{position:fixed;top:20px;right:20px;width:80px;height:80px;border:1px solid #fff;opacity:0.7;transition:all 0.2s 0s ease;cursor:pointer;}
.modal-close:hover{opacity:1;background:rgba(255,255,255,0.2);}
.modal-close div{display:block;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;padding: 0;width: 4px;height: 20px;background: #fff;transform: rotate(45deg);}
.modal-close div:before{display:block;content: "";position: absolute;top: 50%;left: -8px;width: 20px;height: 4px;margin-top: -2px;background: #fff;}
</style>