<template>
	<transition appear>
		<div v-if="isActive" class="pageLoaderWrap">
			<div class="pageLoader"><img src="/img/logo_mark.svg"></div>
		</div>
	</transition>
</template>

<script>
export default {
	name:'pageLoading',
	data:function(){
		return {
			isActive:true
		}
	},
	created:function(){
		window.addEventListener('load',() => {
			setTimeout(() => {
				this.isActive = false;
			},500);
		});
	},
	mounted:function(){
		document.documentElement.style.opacity = '1';
	}
}
</script>

<style scoped>

.v-enter {opacity:1;}
.v-enter .pageLoader{opacity:0;transform:translate(0,50px);}
.v-enter-to .pageLoader{opacity:1;transform:translate(0,0);}
.v-leave {opacity: 1;}
.v-leave-to {opacity: 0;}
.v-leave-to .pageLoader{opacity:0;transform:translate(0,-50px);}

.pageLoaderWrap{display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;width:100%;height:100%;background:#fff;z-index:99999;transition:all 0.5s 0s ease;}
.pageLoader{position:relative;width:100px;transition:all 0.5s 0s ease;-webkit-animation: load5 1.1s infinite ease;animation: load5 1.1s infinite ease;}
.pageLoader img{position:relative;width:100%;}


@-webkit-keyframes load5 {
  0%,100% {opacity:1;}
  50% {opacity:0.5;}
}
@keyframes load5 {
  0%,100% {opacity:1;}
  50% {opacity:0.5;}
}

</style>